import React, { useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom';
import { useAuthToken } from '../../hooks/useAuthToken.js';
import { useFetch } from '../../hooks/useFetch';
import { Toast } from '../../components/controls/UIControls'
import { Modal } from '@mui/material';
export const PlansSectionB2B = () => {
  const { isLogged, authToken, removeAuthToken, removeSessionUser, getSessionUser } = useAuthToken();
  const [showModal , setShowModal] = useState(true);   
  const [data, handleFetch] = useFetch(); 
  const [toastProperties, setToastProperties] = useState({show: false});
  const navigate = useNavigate();
  const handleCloseModal = () => {
      setShowModal(false);
      navigate('/');
  }
  const afterCloseToast = () => {
    setToastProperties({show:false});
  }
  const handleChangeUser = (e) => {
    e.preventDefault();
    const response = handleFetch({
      url:'users/update',
      method: 'POST', 
      data: {
          companyId: 1,
          segmentId: 0,
          type: 1, 
      },
      headers: {Authorization: `Bearer ${authToken}`} 

  });
  response
      .then((r) => {
              setToastProperties({
                  show:true,
                  type:'success',
                  title: '¡Tus datos se han actualizado correctamente!',
                  message: 'Serás redireccionado a EanX para que inicies sesión',
                  position: {horizontal:'left'},
              })
              removeAuthToken();
              removeSessionUser();
              const responselogout = handleFetch({
                url:'users/logout',
                method: 'POST',
                headers: {Authorization: `Bearer ${authToken}`} 
              });
              responselogout
              .then( r => {
                window.location.href = ("https://eanx.co/ingreso");
              },
              error => {
                console.log(error);  
              })
          },
          error => {
              console.log(error.errors);  
          })
  }
  useEffect(()=> {
    /*if(!isLogged()) {
      navigate('/');
    }*/
  },[]);

  return (
    <div style={{marginTop: '90vh'}}>
      { isLogged() && <>
      <Toast {...toastProperties} closeCallback={afterCloseToast} position={{vertical:'bottom', horizontal: 'left'}} ></Toast>
      <Modal
                open={showModal}
                onClose={handleCloseModal}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
            >
                <div className='B2B__modal-base ProfilePage__outline-none p-4'>
                    <form onSubmit={handleChangeUser}>
                        <div className='row justify-content-center align-items-center'>
                            <div className='col-12'>
                                <div className='AcceptDialog__body'>Tu Plan ha Vencido</div>
                                <div className='B2BAdmin__framecourses'>
                                  ¡Oh no! Tu plan corporativo ha Vencido, pero si quieres seguir aprendiendo, puedes adquirir un plan con EanX.
                                </div>
                            </div>
                            <div className='ProfilePage__modal-button-container d-flex justify-content-center'>
                                <div className='row w-100'>
                                    <div className="col-12 col-md-6"><button className='B2B__ActionButton ActionButton--primary' type="submit">Ir a planes EanX</button></div>
                                    <div className="col-12 col-md-6"><button className='B2B__ActionButton ActionButton--secondary' onClick={handleCloseModal}>Cancelar</button></div>
                                </div>
                            </div>
                        </div>
                    </form>
                </div>
            </Modal></> }
    </div>
  )
}
export { PlansSectionB2B as default } from "./PlansSectionB2B.js";
