import { useNavigate } from "react-router-dom";
import { USER_TYPE_EAN_COLLABORATOR, USER_TYPE_EAN_GRADUATE, 
            USER_TYPE_EAN_PAYROLL_TEACHER, USER_TYPE_EAN_STUDENT, 
            USER_TYPE_EAN_SUBJECT_TEACHER, USER_TYPE_GENERAL } from "../assets/constants";
import { useAuthToken } from "./useAuthToken";
import { useFetch } from "./useFetch";
import { useUserPlan } from "./useUserPlan";

// Función para tomar curso
export const useCourseTaker = () => {
    const {checkUserPlan} = useUserPlan();
    const navigator = useNavigate();
    const {authToken, getSessionUser} = useAuthToken();
    const [data, handleFetch] = useFetch();

    const noPlanFlow = () => {
        const sessionUser = getSessionUser();
        if(sessionUser!==undefined) {
            switch(sessionUser.type) {
                case USER_TYPE_GENERAL:
                    gotoPlans();
                    break;
                case USER_TYPE_EAN_STUDENT:
                case USER_TYPE_EAN_PAYROLL_TEACHER:
                case USER_TYPE_EAN_SUBJECT_TEACHER:
                case USER_TYPE_EAN_COLLABORATOR:
                    gotoEanUserVerification();
                    break;
                case USER_TYPE_EAN_GRADUATE:
                default:
                    gotoPlans();
            }
        } else {
            gotoPlans();
        }
    }
    // Función ir a planes
    const gotoPlans = () => {
        navigator('/planes');
    }
    // Función verificar datos
    const gotoEanUserVerification = () => {
        navigator('/verificar-datos');
    }
    // Función tomar curso
    const courseTaker = (course, errorHandler, noPlanHandler = noPlanFlow) => {
        if(!!authToken) {
            checkUserPlan (authToken)
            .then(hasPlan => {
                if(typeof hasPlan == 'string') hasPlan = JSON.parse(hasPlan);
                if(hasPlan) {
                handleFetch({
                    url:`courses/take/${course.contentProviderId}/${course.courseId}`,
                    method: 'POST',
                    headers: {Authorization: `Bearer ${authToken}`} 
                })
                .then (r => {
                    console.log(r);
                    window.open(r.url,'_blank')
                })
                .catch(error => {
                    errorHandler();
                });
            } else {
                noPlanHandler();
            }
            })
        } else {
            navigator('/planes');
        }
    }

    return [data, courseTaker];
}


