import { useNavigate } from "react-router-dom";
import { AUTH_TOKEN_KEY, ADMIN_AUTH_TOKEN_KEY, USER_INFO, ADMIN_USER_INFO } from "../assets/constants";
import { useFetch } from "./useFetch";
import { useCookies } from 'react-cookie';


const AUTH_REDIRECT_PAGE = '/cursos';
const NOAUTH_REDIRECT_PAGE = '/ingreso';

const ADMIN_AUTH_REDIRECT_PAGE = '/admin/usuarios';
const ADMIN_NOAUTH_REDIRECT_PAGE = '/admin/ingreso';

const CHECK_TOKEN_SERVICE = 'users/check-token';
const ADMIN_CHECK_TOKEN_SERVICE = 'bo/check-token';
// Hook para validar token de autorización
export const useAuthToken = (isAdmin=false) => {
    const authTokenKey = isAdmin?ADMIN_AUTH_TOKEN_KEY:AUTH_TOKEN_KEY;
    const authRedirectPage = isAdmin?ADMIN_AUTH_REDIRECT_PAGE:AUTH_REDIRECT_PAGE;
    const noAuthRedirectPage = isAdmin?ADMIN_NOAUTH_REDIRECT_PAGE:NOAUTH_REDIRECT_PAGE;
    const checkTokenService = isAdmin?ADMIN_CHECK_TOKEN_SERVICE:CHECK_TOKEN_SERVICE;
    const userInfo = isAdmin?ADMIN_USER_INFO:USER_INFO;

    const savedToken = localStorage.getItem(authTokenKey);

    const [data, handleFetch] = useFetch();
    const [cookies, setCookie, removeCookie] = useCookies([authTokenKey, userInfo]);


    const sessionToken = cookies[authTokenKey]; // sessionStorage.getItem(authTokenKey);
    
    // Función para actualizar cookie
    const updateCookie = (key, value, options) => {
        removeCookie(key);
        setCookie(key, value, options);
    }

    if(sessionToken == null && savedToken!=null) { // Si hay un token guardado se lleva también a la sesión si no está allí
        updateCookie(authTokenKey,savedToken);
    }

    let authToken = sessionToken;
    const navigate = useNavigate();
    
    // Función de registro token 
    const setAuthToken = (token, saveInStorage = false, user_info) => {
        authToken = token;
        updateCookie(authTokenKey,authToken);
        if(saveInStorage){
            localStorage.setItem(authTokenKey,authToken);
        }
    }
    // Función para eliminar token
    const removeAuthToken = () => {
        removeCookie(authTokenKey);
        localStorage.removeItem(authTokenKey);
        removeCookie(userInfo);
        localStorage.removeItem(userInfo);
        authToken=null;
    }

    //Obtiene el token guardado de la sesión
    const getSavedAuthToken = () => {
        const savedToken = localStorage.getItem(authTokenKey);
        return savedToken;
    }

    // Función para revisar que existe token
    const checkToken = (token) => {
        return new Promise(resolve => {
            handleFetch({
                url:checkTokenService,
                method: 'POST', 
                headers: {Authorization: `Bearer ${token}`}
            })
            .then(() => resolve(true),
                    ()=>resolve(false))
        })
    }
    // Función para redirección con token activo
    const authRedirect = (redirectOnValid=false) => {
        if(authToken) {
            checkToken(authToken)
            .then(valid => {
                if(valid) {
                    removeCookie(authTokenKey); // ELiimino la cookie anterior
                    updateCookie(authTokenKey,authToken); // y creo una nueva ya uqe pueden crearse varias cookies con la misma llave
                    if(redirectOnValid) navigate(authRedirectPage,{replace:true});
                } else {
                    removeAuthToken();
                    navigate(noAuthRedirectPage,{replace:true});
                }
            })
        } else {
            navigate(noAuthRedirectPage,{replace:true});
        }
    }
    // Función para validar usuario logueado 
    const isLogged = () => {
        return (authToken!==null && authToken!==undefined);
    }
    // Función sesión de usuario
    const getSessionUser = () => {
        let sessionUser = cookies[userInfo];
        if(sessionUser) return sessionUser;
        
        const jsonUser =  localStorage.getItem(userInfo);
        if(jsonUser) {
            sessionUser = JSON.parse(jsonUser);
            updateCookie(userInfo, sessionUser);
        }    
        return sessionUser;
    }
    // Función para eliminar sesión de usuario
    const removeSessionUser = () => {
        removeCookie(userInfo);
        localStorage.removeItem(userInfo);
    }
    // Función para actualizar sesión de usuario
    const setSessionUser = (user_info, saveInStorage = false) => {
        updateCookie(userInfo,user_info);
        if(saveInStorage){
            localStorage.setItem(userInfo,user_info);
        }
    }

    return {
        authToken, 
        getSavedAuthToken, 
        checkToken, 
        setAuthToken, 
        removeAuthToken,
        authRedirect,
        isLogged,
        setSessionUser,
        getSessionUser,
        removeSessionUser
    }
}

