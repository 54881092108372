import React, { useContext, useEffect, useState } from 'react'
import { Link, useNavigate, useLocation } from 'react-router-dom';
import { UserContext } from '../../context/UserContext';
import useDropdownMenu from 'react-accessible-dropdown-menu-hook';
import { useFetch } from '../../hooks/useFetch';
import { useAuthToken } from '../../hooks/useAuthToken';
import { useUserPlan } from '../../hooks/useUserPlan';
import { AcceptDialog } from "../controls/UIControls";
const landingImages = require.context('../../assets/images', true);

export const LandingHeader = ({searchAction}) => {
  
  const { buttonProps, isOpen } = useDropdownMenu(2);
  const [isActive, setActive] = useState(false);
  const navigate = useNavigate();
  const [data, handleFetch] = useFetch();
  const {authToken, isLogged, removeAuthToken, removeSessionUser}= useAuthToken();
  const {deleteUserHasPlan, deleteStickyBanner} = useUserPlan();
  const logged = isLogged();
  const location = useLocation();
  const { pathname } = location;
  const {user, setUser} = useContext(UserContext);
  const {getSessionUser} = useAuthToken();
  const [eanActivePlan, setEanActivePlan] = useState(false);
  const [eanuserDialog,setEanuserDialog] = useState(false);
  useEffect(()=> {
    if(logged){
      const sessionUser = getSessionUser();
      setUser(sessionUser);
    }
  },[]);
  const showEanDialog = e => {
    e.preventDefault();
    setEanuserDialog(true);
}


  const toggleClass = () => {
    setActive(!isActive);
    // setWindowYLocation(this.window.scrollY);
  };
  window.addEventListener('scroll', function(){
    if(isActive){
       setActive(!isActive);
    }
});
  const logout = ()=>{
    removeAuthToken();
    removeSessionUser();
    deleteUserHasPlan();
    const response = handleFetch({
      url:'users/logout',
      method: 'POST',
      headers: {Authorization: `Bearer ${authToken}`} 
    });
    response
    .then( r => {
      navigate("/ingreso");
    },
    error => {
      console.log(error);  
    })
    .finally (() => deleteStickyBanner());

  }

  const defaultSearchAction = (keyword) => {
    navigate('/cursos',{state:{keyword}});
  }

  const handleKeyPress = (e) => {
    if(e.charCode===13) {
      if(searchAction) {
        searchAction(e.target.value);
      } else {
        defaultSearchAction(e.target.value);
      }
    }
  }

  const handleCoursesClick = () => {
    if(searchAction) {
      searchAction('');
    }
  }
  const handleTopHome = () => {
      window.scrollTo(0, 0);
  }
  return (
    <>
    <AcceptDialog 
    open={eanuserDialog}
    message="Ya disfrutas de un plan B2B."
    style='info'
    afterClose={()=>setEanuserDialog(false)}
      ></AcceptDialog>
    <div className='px-4 px-sm-5 navbar-nav-scroll navbar-nav LandingHeader__navbar'>
      <div className='row justify-content-between justify-content-lg-evenly justify-md-conent-center align-items-center text-center'>
        <div className='col-4 col-sm-3 col-md-2 col-lg-1 col-xl-1  d-flex justify-content-start'>
          <Link to="/" onClick={handleTopHome}>
              <img alt='Ean logo' className='img-fluid' src={landingImages('./ean-logo-sin 1.png')}  style={{maxWidth:"80px", cursor: "pointer"}}/>
          </Link>
        </div>

        <div className='col-md-4 col-lg-5 col-xl-4 d-none d-lg-block' style={{marginTop: '-10px'}}>
          <div className='' style={{display: "flex", justifyContent: 'center'}}>
          {
              logged
              ?
              <><div className='LandingHeader__text-and-border'>
                  <Link to={'/perfil?profileSection=mis-cursos'} className={`${pathname == '/perfil' ? 'LandingHeader__text-black-color-active' : 'LandingHeader__text-black-color'}`}>
                      <img alt='check icon' src={landingImages('./icons/miscursos.png')}  style={{maxWidth: "24px", minWidth: "20px", marginTop: "-4px"}}/> Mis Cursos
                  </Link>
                </div>
                <div className='LandingHeader__text-no-border'>
                <Link to={'/cursos'} className={`${pathname == '/cursos' ? 'LandingHeader__text-black-color-active' : 'LandingHeader__text-black-color'}`} onClick={handleCoursesClick}>
                    <img alt='check icon' src={landingImages('./icons/catalogo.png')}  style={{maxWidth: "24px", minWidth: "20px", marginTop: "-4px"}}/> Catálogo
                </Link>
              </div></> 
              :
                <></>  
            }
            
          </div>
        </div>
          <div className='d-none d-lg-grid col-md-4 col-lg-2 col-xl-3' style={{marginTop: '-10px'}}>
          {
              logged
              ? <div className="landing__input-wrapper landing__right-addon">
                <i className="search-icon fa fa-search"></i>
                <input className='LandingHeader__input'
                      placeholder=" ¿Qué quieres aprender?"
                      style={{width: "100%"}}
                      onKeyPress={handleKeyPress}/>
                </div> 
              :
                <></>  
            }
              
          </div>
        {
          logged
          ?<>
          <div className='d-flex col-4 col-sm-5 col-lg-3 align-items-center' style={{paddingRight: "6px", marginTop: '-10px'}}>
            <div className='col-11 col-lg-12 d-flex LandingHeader__user-name-and-image justify-content-end'>
              <button className='col-12 LandingHeader__user-name-dropdown-button justify-content-end' {...buttonProps}>
                <span className='LandingHeader__user-name-text d-none d-sm-block'>{user?.name + " " + user?.lastname}</span>
                <span className='LandingHeader__user-name-text d-sm-none'>{user?.name}</span>
                <div className='LandingHeader__user-icon-container'>
                  <i className='fa fa-user' style={{color: "white"}}></i>
                </div>
              </button>
              <div className={isOpen ? 'visible LandingHeader__profile-menu' : ''} style={isOpen? {}:{width: "0px"}} role='menu'>
                <div>
                  <Link to="/perfil" className='LandingHeader__profile-menu-text-color'>
                    <i className='fa fa-user'></i>
                    <span className='ms-2'>
                      Mi perfil
                    </span>
                  </Link>
                </div>
                <div onClick={logout} style={{cursor: "pointer", marginTop: '10px'}}>
                  <i className="fa-solid fa-arrow-right-from-bracket"></i>
                  <span className='ms-2'>
                    Cerrar sesión
                  </span>
                </div>
              </div>
            </div>
            <div className='col-1 d-lg-none text-end d-block pe-0 me-3' onClick={toggleClass}>
              <i className="fa fa-bars"></i> 
            </div>
          </div>
          </>
          :<>
            <div className='col-5 col-sm-3 col-md-2 LandingHeader__ml-auto-max-md  d-none d-lg-block' style={{marginTop: '-10px'}}>
              <div className='row  align-items-center text-center justify-content-end'>
                  <Link to="/ingreso" className='text_primary  col-lg-9 col-xl-8 LandingHeader__secondary-button'>
                <div>
                    Iniciar Sesión
                </div>
                  </Link>
              </div>
            </div>  
            <div className='col-2 d-none d-lg-block ' style={{marginTop: '-10px'}}>
              <div className='row justify-content-center align-items-center text-center'>
              <Link onClick={eanActivePlan?showEanDialog:null} to='/planes/eanx:-dia-de-la-educacion'>
                  <div className='col-lg-11 col-xl-10  LandingHeader__primary-button '>
                  Inscríbete aquí
                  </div>
                </Link>
              </div>
            </div>
            <div className='col-1 d-lg-none text-end d-block d-lg-none pe-0 me-3' onClick={toggleClass}>
              <i className="fa fa-bars"></i> 
            </div>
          </>
        }
      </div>
  {
    isActive &&
      <div className='row justify-content-center py-3' >
        <div className='col-12'>
          {logged?
          <>
          <div className='row text-center justify-content-center'>
          <div className='col-12'>
              <div className="landing__input-wrapper landing__right-addon">
                  <i className="search-icon fa fa-search"></i>
                  <input className='LandingHeader__input'
                         placeholder=" ¿Qué quieres aprender?"
                         style={{width: "100%"}}
                         onKeyPress={handleKeyPress}/>
                </div>
            </div>
          </div>
            <div className='row justify-content-start mt-4'>
              <div className='col-12 LandingHeader__text-no-border'>
                <Link to={'/cursos'} className="LandingHeader__text-black-color" onClick={handleCoursesClick}>
                    <img alt='check icon' src={landingImages('./icons/catalogo.png')}  style={{maxWidth: "24px", minWidth: "20px", marginTop: "-4px"}}/> Catálogo
                </Link>
              </div>
            </div>
            <div className='row justify-content-start mt-4'>
              <div className='col-12 LandingHeader__text-no-border'>
                <Link to={'/perfil?profileSection=mis-cursos'} className="LandingHeader__text-black-color">
                    <img alt='check icon' src={landingImages('./icons/miscursos.png')}  style={{maxWidth: "24px", minWidth: "20px", marginTop: "-4px"}}/> Mis Cursos
                </Link>
              </div>
            </div></>
          :
          <></>
          }
          {logged
          ?<></>
          :<div className='row  text-center mt-3 pb-2 justify-content-center'>
              <Link to="/ingreso" className='col-11 col-sm-5 mt-3 LandingHeader__secondary-button text_primary '>
                <div>
                    Iniciar Sesión
                </div>
              </Link>
                  <div className='col-1 col-sm-1 d-none d-sm-block d-md-block d-xl-none'> </div>
              <Link to="/planes/eanx:-dia-de-la-educacion"  className='d-flex justify-content-center col-11 col-sm-5 mt-3 LandingHeader__primary-button'>
                <div >
                Inscríbete aquí
                </div>
              </Link>
          </div>
          }
        </div>

      </div>
  }
    </div></>
  )
}
