import React, { useEffect } from 'react'
import { SimpleFooter } from '../footer/Footer'
import { LandingHeader } from '../landing/LandingHeader'
import { PlansSectionB2B } from '../landing/PlansSectionB2B'
/**
 * Creado por Equilibrio Agency 2022<br>
 * Página para mostrar listado de planes
 * @return {HTMLElement} Elementos HTML de cada componente
 * @interface PlanPage
 */
export const PlanPage = () => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return (
    <div>
        <LandingHeader />
        <div style={{marginTop: "70px"}}></div>
        <PlansSectionB2B />
        <div className='mb-5'>
        </div>
        <SimpleFooter />
    </div>
  )
}
