import { useEffect, useRef, useState } from 'react'
import { apiConfig } from '../api/apiConfig';
// Función para consultar endpoints
export const useFetch = ( url, requestOptions ) => {

    const isMounted = useRef(true);
    const [state, setState] = useState({ data: null, loading: false, error: null });
    useEffect( () => {
        return () => {
            isMounted.current = false;
        }
    }, [])
    // Handle Fetch, recibe url de endpoint, método, data para body y headers
    const handleFetch = ({ url, method, data, headers }) => {
        requestOptions = {
            method: method,
            headers: Object.assign({},apiConfig.header, headers),
            body: JSON.stringify(data)
            };

        setState({ data: null, loading: true, error: null });
        return fetch( `${ apiConfig.baseUrl }${url}`,  requestOptions)
        .then( async resp => {
            const rJson = await resp.json();
            if(!resp.ok)  {
                setState({
                    loading: false,
                    error: rJson.errors
                });
                return Promise.reject(rJson)
            }
            else
                return rJson;
        } )
        .then( data => {
            setState({
                loading: false,
                error: null,
                data
            });
            return data;
        })
        .catch (error => {
            setState({
                loading: false,
                error
            });
            return Promise.reject(error)

        })

    }

    return [state, handleFetch];
    
}