import { Alert, Backdrop, CircularProgress, Collapse } from '@mui/material'
import React, { useState } from 'react'
import { useEffect } from 'react';
import { Link, useLocation, useSearchParams, useNavigate } from 'react-router-dom';
import { useGetPlan } from '../../hooks/useGetPlan';
import { useFetch } from '../../hooks/useFetch';
import { MessageDialog, AuthFormContainerPage } from '../controls/UIControls'
import { PLAN_TYPE_EAN_STUDENT_TACHER_COLLABORATOR } from '../../assets/constants';
//import { apiConfig } from '../../api/apiConfig';

const landingImages = require.context('../../assets/images', true);
/**
 * Creado por Equilibrio Agency 2022<br>
 * Sección cuenta creada
 * @function AccountCreatedPage
 */
export const AccountCreatedPage = () => {
    const [showAlert, setShowAlert] = useState(false);
    const [planAssociated, setPlanAssociated] = useState(true);
    const [planAssociatedMessage, setPlanAssociatedMessage] = useState('');
    const [getPlanStarted, setGetPlanStarted] = useState(false);
    const [processing, setProcessing] = useState(false);
    const location = useLocation();
    const  [searchParams] = useSearchParams();
    const {getPlan, associatePlan, mpPreference} = useGetPlan();
    const [namePlan, setNamePlan] = useState('');
    const [data, handleFetch] = useFetch();

    const {user, plan} = location.state?location.state:{plan:{}, user:{}};

    const PLAN_ASSOCIATED = 'El plan fue asociado correctamente a tu cuenta.';
    const PLAN_NOT_ASSOCIATED = 'Ocurrió un error al asociar el plan seleccionado a tu cuenta.';
    const PLAN_PURCHASED = 'La compra de tu plan se realizó con éxito.';
    const PLAN_NOT_PURCHASED = 'El pago de tu plan fue rechazado.';

    //const planAssociated = location.state?.planAssociated
    const [buttonProperties, setButtonProperties] = useState({label:'Ir al login', linkProps: {to:'/ingreso'}});

    const navigate = useNavigate();
    const [dialogProperties, setDialogProperties] = useState({open:false});
    const afterCloseErrorDialog = () => {
        setDialogProperties({open:false});
        navigate("/ingreso");
    }
    // Handle para gestionar estado de registro
    const handleGetPlanResponse = status => {
        setGetPlanStarted(true);
        switch(status) {
            case 'assigned':
                setPlanAssociated(true);
                setPlanAssociatedMessage(PLAN_ASSOCIATED);
                setShowAlert(true);
                break;
            case 'not_assigned':
                setPlanAssociated(false);
                setPlanAssociatedMessage(PLAN_NOT_ASSOCIATED);
                setShowAlert(true);
                break;
            case 'purchase':
                setShowAlert(false);
                break;
            case 'approved':
                setPlanAssociated(true);
                setPlanAssociatedMessage(PLAN_PURCHASED);
                setShowAlert(true);
            case 'rejected':
                setPlanAssociated(false);
                setPlanAssociatedMessage(PLAN_NOT_PURCHASED);
                setShowAlert(true);
                }
    };
    // Función para gestión de respuesta en pago
    const checkPurchaseResponse = () => {
        if(!getPlanStarted) {
            const status = searchParams.get('status');
            const preference = searchParams.get('preference_id');
            const userPlanData = searchParams.get('external_reference');
            const paymentId = searchParams.get('payment_id');
            const merchandOrderId = searchParams.get('merchant_order_id');

            if( [status, preference, userPlanData, paymentId, merchandOrderId].every(p => p!==null))
                setGetPlanStarted(true);
                getNamePlan();
        }
    }
    //Consulto endpoint de inicio de sesión para obtener token
    /*const getAccessToken = () => {
    return new Promise(function(resolve, reject) {
        handleFetch({
            url: apiConfig.webhook.ACCESS_ENDPOINT,
            method: 'POST', 
            data: {
                email: apiConfig.webhook.CLIENT_ID,
                password: apiConfig.webhook.CLIENT_SECRET,
                strategy:'local'
               }
        })
        .then(response => resolve(response.accessToken))
        .catch(error => reject(error))
    });
  }
    //Consulto endpoint de planes contratados por usuario (Recibo id del usuario por parámetro)
    const getUserEndPlans = (accessToken,id) => {
        return new Promise((resolve, reject) => {
            handleFetch({
                url: "bo/userplans?userId="+`${parseInt(id)}`,
                method: 'GET',
            headers: {Authorization: `Bearer ${accessToken}`}
            })
            .then(response => {
                resolve(response.data)}
            )
            .catch(error => reject(error))
        })
    }*/
    // Función para asociar el plan en caso de pago aprobado
    const processAnswer = async () => {
        /*let _listPayments = [];
        let approvalCodeMP = "";
        let progressResponse = [];
        let flag = false;*/
        //Almaceno valor de external_reference para asignación el plan si es necesaria
        const status = searchParams.get('status');
        const userPlanData = searchParams.get('external_reference');
        const paymentId = searchParams.get('payment_id');
        const merchandOrderId = searchParams.get('merchant_order_id');
        if(status=='approved') {
            const userPlan = userPlanData.split('-');
        /*    return new Promise (async (resolve, reject) => {
                  //Concateno id de pago y order id para validar con approvalCode en base de datos
                  approvalCodeMP = searchParams.get('payment_id')+"-"+searchParams.get('merchant_order_id')
                  let valuetoken = await getAccessToken()
        
                  // Consulto los planes contratados de ese usuario (id del usuario viene de userPlan[0])
                  progressResponse = await getUserEndPlans(valuetoken,userPlan[0]);
                  _listPayments.push(progressResponse);
        
                  if(progressResponse.length > 0){
                    for(let i=0; i<progressResponse.length; i++){
                      if(approvalCodeMP == _listPayments[0][i].approvalCode){
                        flag = true;
                      }
                    }
                  }
                  if(flag == true){
                    // Retorna error
                    reject("EL PLAN NO PUEDE SER ASIGNADO")
                  }else{*/
                    // Se asigna el plan
                    associatePlan({id: parseInt(userPlan[0])}, {id: parseInt(userPlan[1])},`${paymentId}-${merchandOrderId}` )
                    .then (handleGetPlanResponse);
           /*         resolve(true);
                  }
            });*/
        } else {
            handleGetPlanResponse(status);
        }
    }
    // Función mostrar modal en caso de registro usuario eanista
    const setEanPlanContent = () => {
        var date = new Date();
        var quarter =  parseInt(date.getMonth() / 6 ) + 1 ;
        let finalCourse = quarter === 1 ? '30 de junio' : '31 de diciembre';
        setDialogProperties({open:true,
            title: 'Bienvenido Eanista',
            image: true,
            message:'Por ser parte de nuestra comunidad tienes acceso a EanX' +
                ' y todos sus beneficios hasta el final de este semestre (<b>'+finalCourse+'</b>). Aprende y evoluciona.',
            messagetwo: 'Recuerda que al inicio de cada semestre deberás activar tu cuenta nuevamente.',
            afterClose: afterCloseErrorDialog,
            button_text: 'Continuar',
            style:'ok'});

    }
    // Función para asignar plan
    const startPlanAssociation = () => {
        console.log("datos del plan",plan)
        setProcessing(true);

        getPlan(user,plan,false)
        .then(handleGetPlanResponse)
        .finally(()=>{setProcessing(false);});

        if(plan.type === PLAN_TYPE_EAN_STUDENT_TACHER_COLLABORATOR) {
            setEanPlanContent();
        }

    }
    // Función de asignación de plan manual
    const handleClick = (e) => {
        e.preventDefault();
        startPlanAssociation();
    }


    useEffect(()=> {
        if(!getPlanStarted) {
            if(plan.price==0 || plan.type === PLAN_TYPE_EAN_STUDENT_TACHER_COLLABORATOR) {
                startPlanAssociation();
            } else {
                setButtonProperties({label:'Continuar...', linkProps: {to:'/', onClick: handleClick}});
            }
        } else {
            processAnswer();
            setButtonProperties({label:'Ir al login', linkProps: {to:'/ingreso'}})
        }
    },[getPlanStarted, mpPreference]);

    const properties = {
        title: "Tu cuenta ha sido registrada exitosamente",
        header: (<div>
                    <img 
                        alt='Ean logo' 
                        src={landingImages('./check.png')} 
                        style={{width: "100px", height: "auto"}} 
                        className="mx-auto d-block auth__img-check mb-3"
                    />
                </div>),
        content: (<Link {...buttonProperties.linkProps} replace>
                    <button className="auth__btn-form">{buttonProperties.label}</button>
                    </Link>
                    )
    }

    const propertiespurchase = {
        title: "Tu compra del plan "+namePlan+" ha sido registrada exitosamente",
        header: (<div>
                    <img
                        alt='Ean logo'
                        src={landingImages('./check.png')}
                        style={{width: "100px", height: "auto"}}
                        className="mx-auto d-block auth__img-check mb-3"
                    />
                </div>),
        content: (<Link {...buttonProperties.linkProps} replace>
                    <button className="auth__btn-form">{buttonProperties.label}</button>
                    </Link>
                    )
    }
    // Función para obtener nombre de plan
    const getNamePlan = () => {
        const userPlanData = searchParams.get('external_reference');
        const { plan } = location.state?location.state:{plan:{}};
        const userPlan = userPlanData ? userPlanData.split('-') : '';
        let idplan = userPlanData ? parseInt(userPlan[1]) : parseInt(plan.id);
        const status = searchParams.get('status');
        if(status=='approved') {
            const response = handleFetch({
                url: `bo/plans?id=${idplan}`,
                method: 'GET',
            });
            response
                .then((r) => {
                        setNamePlan(r[0].title)
                    },
                    error => {
                        console.log(error.errors);
                    })
        }
    }
    checkPurchaseResponse();

    return (
            <>
            <MessageDialog {...dialogProperties}></MessageDialog>
            <Backdrop
            sx={{ color: '#fff'}}
            open={processing}
            >
            <CircularProgress color="inherit" />
            </Backdrop>
            <Collapse in={showAlert}>
                <Alert severity={planAssociated?"success":"error"} onClose={()=>{setShowAlert(false)}}>
                    {planAssociatedMessage}
                </Alert>
            </Collapse>
                { location?.state?.plan?.price == 0 ?
                    <AuthFormContainerPage {...properties}></AuthFormContainerPage> :
                    <AuthFormContainerPage {...propertiespurchase}></AuthFormContainerPage>
                }
            </>
            )
}
