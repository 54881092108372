// Your web app's Firebase configuration
export const apiConfig = {
    // Api Key para conexión
    header: {
        'Content-Type': 'application/json',
        'x-api-key': 'bxwN1xx0r5aW2pm6EudrkRWk9Z0hFAHvkRK9YQiD3acbn6MuAzpHqCcM4WHg'
    },
    company: 11,
    baseUrl: 'http://eanx-api.eastus2.cloudapp.azure.com/qa/',
    mercadoPago: {
        returnUrl: 'http://prospective-agency-qa.eastus2.cloudapp.azure.com/cuenta-creada'
    }
  };