import React, { useContext, useEffect, useState } from 'react'
import ReactPaginate from 'react-paginate';
import { courseCategories } from '../../data/courseCategories';
import { useFetch } from '../../hooks/useFetch';
import { useAuthToken } from '../../hooks/useAuthToken';
import { UserContext } from '../../context/UserContext';
import Modal from '@mui/material/Modal';
import { Link } from 'react-router-dom';
import { apiConfig } from '../../api/apiConfig';
const landingImages = require.context('../../assets/images', true);
const loadingImage = landingImages('./icons/loading_spin_ean.svg');

const coursesContainerId = 'courses-container';
const courseActionButtonPrefix = 'ab_course_';

const breakpoints = {sm: 576, lg: 992}; //sm -> 2 col, lg -> 3 cols

let coursesContainer = null;

const findPos = obj => {
	var curleft = 0, curtop = 0;
    if (obj.offsetParent) {
        do {
			curleft += obj.offsetLeft;
			curtop += obj.offsetTop;
        } while (obj = obj.offsetParent);
    }
    return [curleft,curtop];
}
const getRowCols = () => {
    let nrows  = 3;
    let ncols = 1;
    const vpw = window.innerWidth;
    if(vpw<breakpoints.sm) {
        nrows = 4;
    } else if(vpw>=breakpoints.sm && vpw<=breakpoints.lg) {
        ncols = 2
    } else {
        ncols = 3;
    }
    return [nrows, ncols];
}

const [nrows, ncols] = getRowCols();
const pageSize = ncols*nrows;;
/**
 * Creado por Equilibrio Agency 2022<br>
 * Función para llamar a endpoint de catálogo de cursos
 * @param {function} fetcher Función para llamar endpoint con listado de cursos
 * @param {string} page Número de página
 * @param {string} pageSize Tamaño de paginación
 * @param {string} options Opciones para filtro
 * @function fetcherFunction
 */
const fetcherFunction = (fetcher, page, pageSize,options='',user) => {
    const skip = (page-1) * pageSize;
    let segment = user?.segmentId ? `&segment=${user.segmentId}` : ''
    return fetcher({
        url:`catalog/b2bcatalog?company=${apiConfig.company}&$limit=${pageSize}${segment}&$skip=${skip}&${options}`,
        method: 'GET',
    });
}
/**
 * Creado por Equilibrio Agency 2022<br>
 * Función para llamar a endpoint de catálogo de cursos
 * @param {string} totalPages Total de páginas disponibles
 * @param {string} pageSetter Avanzar en número de página
 * @param {string} backToFirstPage Forza ir a la primera página
 * @param {boolean} coursesLoaded Muestra u oculta paginador de cursos
 * @function CoursePaginator
 */
// Función para paginar cursos
const CoursePaginator = ({totalPages, pageSetter, backToFirstPage, coursesLoaded}) => {
    const handlePageClick = (event) => {
        pageSetter(event.selected+1);
    }
    const forcePage = backToFirstPage?{forcePage:0}:{};

    return (
        <div className={`d-flex justify-content-center mt-3 mb-4 ${coursesLoaded?'visible':'invisible'}`} >
            <ReactPaginate
                className="pagination"
                pageClassName="CatalogPage__page-item d-none d-sm-block mx-sm-1"
                pageLinkClassName="CatalogPage__page-link page-link"
                activeClassName="CatalogPage__page-item--active"
                previousClassName="CatalogPage__page-item"
                nextClassName="CatalogPage__page-item"
                previousLinkClassName="CatalogPage__page-link--nav page-link"
                nextLinkClassName="CatalogPage__page-link--nav page-link"
                breakLabel="..."
                breakClassName='d-none d-sm-block'
                nextLabel="Siguiente >"
                onPageChange={handlePageClick}
                pageRangeDisplayed={2}
                marginPagesDisplayed={3}
                pageCount={totalPages}
                previousLabel="< Anterior"
                renderOnZeroPageCount={null}
                {...forcePage}
            />
        </div>
    );
}
// Función para mostrar contenido de cursos
const CourseCardContentContainer = ({content}) => {
    return (
    <div className='row justify-content-start align-items-center text-start mx-sm-2 mb-2 CourseCard__container-text'>
    {content}
    </div>);
}
// Función para mostrar información básica de cursos
const CourseBasicData = ({course}) => {
    return (
        <>
            <div className='col-12 '>
                <div className='row'>
                    <div className="col-12"> 
                        <p className='CourseCard__text mb-0 mt-1'>{course['course.name']}</p>
                    </div>
                </div>
                <div className='row'>
                    <div className='col-12'>
                        <p className='CourseCard__text-instructor mb-1'>Por {course['course.instructorData'].name}</p>
                    </div>
                </div>
            </div>
        </>
    )
}
// Función para mostrar información detallada de cursos
const CourseExtraData = ({course}) => {
    const desc_length = 110;
    const description = (!!course['course.description'])?(course['course.description'].length<desc_length)?course['course.description']:(course['course.description'].substring(0,desc_length-1)+'...'):'';
    const show_correct_time = (time_minutes) =>{
        return Math.floor(time_minutes/60)+"h "+time_minutes%60+"m";
    }
    let nameContent = typeContent(course['course.title']);
    return (
    <>
        <div className='row align-items-baseline mt-2'>

            <div className='col-6 d-flex align-items-baseline'>
                <img alt={'Clock icon'} src={landingImages(`./icons/clock-mini-icon.svg`)} style={{width:"18px", height:"auto"}} /> 
                <p style={{fontSize: "15px", fontWeight: "700", color: "grey", marginLeft: "10px"}}>
                    {show_correct_time(course['course.duration'])}
                </p>
            </div>

            {/*<div className='col-5 d-flex align-items-baseline'>
                <img alt={'People icon'} src={landingImages(`./icons/students-icon.svg`)} style={{width:"18px", height:"auto"}} />
                <p style={{fontSize: "15px", fontWeight: "700", color: "grey", marginLeft: "10px"}}>
                    {course["attr.enrolledUsers"]} estudiantes
                </p>
            </div>*/}

            <div className='col-6 d-flex align-items-baseline'>
                <img alt={'Level icon'} src={landingImages(`./icons/level-icon.svg`)} style={{width:"18px", height:"auto"}} />
                <p style={{fontSize: "15px", fontWeight: "700", color: "grey", marginLeft: "10px"}}>{nameContent}</p>
            </div>
                            
        </div>
        <div className='row text-justify'>
            <div className='col-12 h-100'>
                <p className='BestCourses__text-description'>{description}</p>
            </div>
        </div>
        <div className='row justify-content-center text-center'>
                <Link className='row justify-content-center text-center' to={`/curso/${course['course.courseId']}`}>
                    <div className='col-12 BestCourses__button-primary mb-3'
                        id={`${courseActionButtonPrefix}${course['id']}`}
                    >
                        Ver curso
                    </div>            
                </Link>
        </div>
    </>
)
}
// Función para mostrar tarjeta con información de cursos
const CourseCard = ({course, id, onMouseEnter, onMouseLeave}) => {
    const insideContent = <CourseCardContentContainer 
                                content={<CourseBasicData course={course}/>}>
                        </CourseCardContentContainer>
    return <CourseCardContainer course = { course } 
                                id = { id }
                                insideContent ={ insideContent } 
                                onMouseEnter = { onMouseEnter } 
                                onMouseLeave = { onMouseLeave }
                                className = 'col-12 col-sm-6 col-lg-4 p-0'
                                />;
}
// Función para mostrar detalle de cursos
const CourseDetails = (props) => {
    const [ready, setReady] = useState(false);
    const [offset, setOffset] = useState(0);
    
    const vpw = window.innerWidth;
    const extraSize = (vpw<breakpoints.sm)?0:15; // No se agranda en móviles
    let course = props.course;

    const handleEnrollClick = (courseID)=>{
        console.log(courseID);
        
    }

    useEffect(() => {
        if(!ready) {
            if(props.alignBottom) {
                const courseDetailsBox = document.getElementById('course-details');
                setOffset(props.height - courseDetailsBox.offsetHeight);
            } else {
                setOffset(0);
            }
            setReady(true);
        }
    },[ready]);


    const onMouseLeave = (e) => {
        setReady(false);
        if(props.onMouseLeave) props.onMouseLeave(e);
    }

    const onTouchEnd = (courseId) => (e) => {
        if(e.target.id!=`${courseActionButtonPrefix}${courseId}`)
            onMouseLeave(e);
    }

    const insideContent = (
        <CourseCardContentContainer
            content = {<>
                        <CourseBasicData course={course} />
                        <CourseExtraData course={course} />
                        </>}
        >
        </CourseCardContentContainer>
    );
    return (
        <div className={`position-absolute p-0  ${ready?'visible':'invisible'}`}
            id='course-details'
            style={{
                    top: props.top + offset, 
                    left: props.left-extraSize/2, 
                    width: props.width+extraSize, 
                    zIndex:10}}
            onMouseLeave={onMouseLeave}
            onTouchEnd={onTouchEnd (course.id)}
        >
        <CourseCardContainer 
                    course = {course}
                    insideContent = {insideContent}
                />
        </div>);

}
// Función para mostrar tarjeta con información de cursos
const CourseCardContainer = ({id,course,insideContent, onMouseEnter, onMouseLeave, className}) => {
    const [timer, setTimer]=useState(0);
    const onTouchStart = (e) => {
        if(onMouseEnter) {
            const _timer = setTimeout(()=>onMouseEnter(e),200);
            setTimer(_timer);
        }
    }

    // Intento (fallido) de que no aparezca el detalle del curso con el scroll...
    // Hay que averiguar el evento adecuado para tal fin
    const onScroll = (e) => {
        clearTimeout(timer);
    }
    return (
    <div 
        className= {className} 
        id={id}
        onMouseEnter={onMouseEnter} 
        onMouseLeave={onMouseLeave}
        onTouchStart={onTouchStart}
        //onScrollCapture ={onScroll}
    >
        <div className='row justify-content-center justify-content-sm-start m-0'>
            <div className='col-12 p-0' >
                <div className='row mx-sm-2 mt-2 position-relative'>
                    <div className='col-12 p-0'>
                        {
                            course['course.imageUrl'] ? <img alt={'Course thumbnail '+course['course.name']} src={course['course.imageUrl']} className='CourseCard__image' />
                                : <img alt={'Course thumbnail '+course['course.name']} src="http://eanx-api.eastus2.cloudapp.azure.com/qa/catalog/images/curso22.jpeg" className='CourseCard__image' />
                        }
                        <div className='row CourseCard__time justify-content-center'>
                            { formatDuration(course['course.duration']) || '0h 0m' }
                        </div>
                    </div>
                </div>
                    {insideContent}
            </div>
        </div>
    </div>);
}

// Función para mostrar listado de cursos
const CoursesList = ({courses, page_size, total_pages}) => {
    const [activeCardProperties, setActiveCardProperties] = useState(null);

    const handleMouseEnter = (index, alignBottom, course) => (event) => {
        const card = document.getElementById('course-'+index);
        const [left, top] = findPos(card);


        setActiveCardProperties({
                                top: top - coursesContainer.offsetTop, 
                                left: left - coursesContainer.offsetLeft, 
                                width: card.offsetWidth,
                                height: card.offsetHeight,
                                alignBottom,
                                course});
    };

    const handleMouseLeave = () => {
        setActiveCardProperties(null);
    }
    return (
        <div className="row m-0">
        {activeCardProperties && <CourseDetails onMouseLeave={handleMouseLeave} {...activeCardProperties}></CourseDetails>}
        {
            
            courses.map((course,index) => {
                const currentRows = Math.ceil(courses.length/ncols);
                const elementRow = Math.ceil((index+1)/ncols);
                const alignBottom = ((currentRows>1) && (elementRow === currentRows));
                return (<CourseCard course={course} 
                                            id = {`course-${index}`}
                                            key={`${course.contentProviderId}_${course.courseId}`}
                                            onMouseEnter = {handleMouseEnter(index,alignBottom,  course)} 
                                            /*onMouseLeave = {handleMouseLeave} *//>
                )})
        }
        </div>
    );
}
// Función loading
const Loading = (props) => {
    const loaderImage = props.loaderImage || loadingImage;
    return (
        <div className='row text-center position-absolute CatalogPage--loader'>
            <img alt='Loader' src={loaderImage}/>
        </div>
    );
}

const CoursesLoader = ({coursesNumberSetter, 
                            searchOptions, 
                            resultSortOptions,
                            coursesLoadedSetter,
                            totalPagesSetter, 
                            initPagerSetter,
                            page,
                            user}) => {
    const [data, handleFetch] = useFetch();
    const [courses, setCourses] = useState([]);
   
    const coursesFetcher = (page, setTotal=false) => {
        let options = '';
        window.scrollTo(0, 0);
        if(searchOptions.keyword) {
            let t = encodeURI('%' + searchOptions.keyword + '%');
            options = "search=" + t;
        } else if (searchOptions.category!==undefined && searchOptions.category>0) {
            options = "category="+searchOptions.category;
        } else if (searchOptions.sort) {
            options = "sort="+ searchOptions.sort;
        }
        if(resultSortOptions!=null) {
            options+=((options!='')?'&':'')+resultSortOptions.sortParam;
        }
        fetcherFunction(handleFetch, page, pageSize,options,user)
        .then( r => {
            setCourses(r.data);
            initPagerSetter(setTotal);
            
            if(setTotal) {
                coursesNumberSetter(r.total);
                totalPagesSetter(Math.ceil(r.total/pageSize));
            }
        }, error => {
                console.log(error);
            });
                  
    }

    useEffect(() => {
        coursesFetcher(1,true);
    }, [searchOptions, resultSortOptions]);
    
    useEffect(() => {
        coursesFetcher(page,false);
    }, [page]);


    useEffect(() => {
        coursesLoadedSetter(!data.loading);
    }, [data]);

    return (   
        <div id={coursesContainerId}
            className='justify-content-center justify-content-sm-start position-relative'>
            {
                data.loading? 
                    <Loading></Loading>
                :
                    <CoursesList 
                        courses={courses} 
                        page_size={pageSize}
                    ></CoursesList>
                }
        </div>
    );
}

const SortOption = ({value, title, sortOptions}) => {
    return <option value={value} selected={sortOptions && sortOptions.sortOption == value} >{title}</option>

}
// Función para sección de cursos
const CoursesSection = ({setShowModalFilter, searchOptions, user}) => {
    const [numberCourses, setNumberCourses] = useState(0);
    const [coursesLoaded, setCoursesLoaded] = useState(false);
    const [totalPages, setTotalPages] = useState(0);
    const [currentPage, setCurrentPage] = useState(1);
    const [initPager, setInitPager] = useState(true);
    const [sortOptions, setSortOptions] = useState(null);

    useEffect(()=> {
        setCoursesLoaded(false);
        setCurrentPage(1);
    }, [searchOptions, sortOptions]);

    // Función para ordenar cursos
    const reorderCoursed = (ev) => {
        console.log(ev);
        switch(ev.target.value) {
            case 'duration_asc':
                setSortOptions({sortOption:ev.target.value, sortParam:'$sort[duration]=1'});
                break;
            case 'duration_desc':
                setSortOptions({sortOption:ev.target.value, sortParam:'$sort[duration]=-1'});
                break;
            case 'subs':
                setSortOptions({sortOption:ev.target.value, sortParam:'sort=enrolled'});
                break;

        }
    }

    return (
    <div className='col-12 col-lg-9'>
        <div className='row px-sm-5 justify-content-center justify-content-sm-end d-lg-none' onClick={() => setShowModalFilter(true)}>
        <div className='col-10 col-sm-3 text-center CatalogPage__filter-container'>
            <i className='fa fa-filter'></i><span className='CatalogPage__filter-text'>Filtrar</span>
        </div>
        </div>
        {
            coursesLoaded?
            (
                (numberCourses>0 )?
                (
                <>
                    <div className='row justify-content-center justify-content-sm-start'>
                        <div className='col-10 CatalogPage__title-container p-0 px-sm-2'>
                            <span className='CatalogPage__title'>Nuestro Catálogo</span>
                        </div>
                    </div>
                    <div className='row d-none d-sm-flex justify-content-center justify-content-sm-start'>
                        <div className='col-12 CatalogPage__title-container'>
                            <div className='d-flex justify-content-between me-2'>
                                <span className='CatalogPage__results'>{numberCourses} resultados</span>
                                <div className='CatalogPage__right-order'>
                                    <div className='CatalogPage__titleorder'>Ordenar por</div>
                                        <select onChange={reorderCoursed} className='CatalogPage__selectOrder' >
                                            <SortOption sortOptions={sortOptions} value='subs' title='Más Vistos'></SortOption>
                                            <SortOption sortOptions={sortOptions} value='duration_asc' title='Menor Duración'></SortOption>
                                            <SortOption sortOptions={sortOptions} value='duration_desc' title='Mayor Duración'></SortOption>
                                        </select>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className='row d-flex d-sm-none justify-content-center'>
                        <div className='col-10 CatalogPage__title-container p-0'>
                            <span className='CatalogPage__results'>{numberCourses} resultados</span>
                        </div>
                    </div>
                    <div className='row d-flex d-sm-none justify-content-center'>
                        <div className='col-10 CatalogPage__title-container p-0 me-2'>
                            <span>Ordernar por:</span><span>Más vistos</span>
                        </div>
                    </div>
                </>)
                :
                (<div className="col mx-sm-5">
                    <div className="text-center text_primary my-5" >
                        <i className="fa-solid fa-heart-crack fa-4x mb-3"></i>
                        <h3>¡Qué raro! No hemos encontrado lo que esperas. Te sugerimos: </h3>
                    </div>
                    
                    <ol>
                        <li>Revisar la forma en que lo escribes.</li>
                        <li>Escribirlo en otro idioma (Por ejemplo en inglés o español)</li>
                        <li>Ser más específico.</li>
                    </ol>
                </div>)
            )
            :
            <></>
        
        }
        <CoursesLoader 
            coursesNumberSetter={setNumberCourses} 
            searchOptions={searchOptions}
            resultSortOptions={sortOptions}
            coursesLoadedSetter={setCoursesLoaded}
            totalPagesSetter={setTotalPages}
            initPagerSetter = {setInitPager}
            page={currentPage}
            user={user}
        >
        </CoursesLoader>
        <CoursePaginator 
            pageSetter={setCurrentPage} 
            totalPages = {totalPages}
            backToFirstPage = {initPager}
            coursesLoaded = {coursesLoaded}
        ></CoursePaginator>
    </div>
    )

}

//Función para ordenar según el tipo (Más buscados, mas certificados, más nuevos)
const OrderLinks = ({orderSetter, currSort}) => {
    const handleOrderClick = (order) => {
        orderSetter({sort:order});
    }
    
    const orders = {
        mostSearched: 'enrolled',
        mostCertified: 'certified',
        mostRecent: 'creation'
    }
    return (<>
        <div className='row'>
            <div className={`col-12 CatalogPage__order-text ${(currSort===orders.mostSearched)?'text_primary':''}`}
                onClick={()=>handleOrderClick(orders.mostSearched)}>                  
                Más buscados
            </div>
        </div>
        <div className='row'>
            <div className={`col-12 CatalogPage__order-text ${(currSort===orders.mostCertified)?'text_primary':''}`}
                onClick={()=>handleOrderClick(orders.mostCertified)}>                  
                Más certificados
            </div>
        </div>
        <div className='row CatalogPage__orders-container'>
            <div className={`col-12 CatalogPage__order-text ${(currSort==orders.mostRecent)?'text_primary':''}`}
                onClick={()=>handleOrderClick(orders.mostRecent)}>                  
                Nuevos
            </div>
        </div>
    </>);
}
// Función para mostrar duración de cursos
const formatDuration = (duration) => {
    if (duration==null) return null;
    const hours = Math.floor(duration/60);
    const minutes = duration % 60;
    return `${hours}h ${minutes}m`;
}
// Función para devolver tipo de contenido de cursos o cápsulas
const typeContent = (title) => {
    var content = title.indexOf('Cápsula');
    if(content >=0){
        return 'Cápsula'
    }
    return 'Curso';
}
// Función para filtras cursos según opción recibida del menú
const FilterMenu = ({handleCloseModal, isAModal, searchOptionsSetter, searchOptions }) => {
    return  (
    <>
    {isAModal
    ?
        <div className='CatalogPage__Filter-Modal'>
            <div className='row' style={{marginLeft: "20px"}}>
                <div className='col-12'>
                    <div className='row' onClick={handleCloseModal} style={{marginTop: "15px", marginBottom: "60px"}}>
                        <div className='col-12'>                     
                            <i className='fa-solid fa-xmark' style={{width: "14px", height: "14px"}}></i>
                        </div>
                    </div>
                    <OrderLinks orderSetter={searchOptionsSetter} currSort={searchOptions.sort}/>
                    <CategoriesList 
                        filterSetter={searchOptionsSetter} 
                        searchOptions={searchOptions}>
                    </CategoriesList>
                </div>
            </div>
        </div>
    :
        <div className='col-3 d-none d-lg-block'>
            <OrderLinks orderSetter={searchOptionsSetter} currSort={searchOptions.sort}/>
            <CategoriesList 
                filterSetter={searchOptionsSetter}
                searchOptions={searchOptions}>
            </CategoriesList>
        </div>

    }
    </>

    );

}
// Función para mostrar listado de categorías
const CategoriesList = ({filterSetter, searchOptions}) => {
    const categories = [{name: "Todas las categorías", id: 0, icon:'todos'}]
                                .concat( courseCategories.map(cat=>(
                                            {name:cat.name,
                                            id: cat.id,
                                            icon: cat.icon}
                                        )));
    const duration = [
        {name: "Todos", id: 0, icon:'todos'},
        {name: "Cápsula Express (Hasta 2 horas)", id: 9, icon:'todos'},
        {name: "Curso Experto (2 horas o más)", id: 10, icon:'todos'},];

    
    const handleCategoryClick = (id) => {
        filterSetter({category:id});
    }
    return (
        <>
            <div className='row mt-4'>
                <div className='col-12'>
                    <span className='CoursePage__categories-title'>Categorías</span>
                </div>
            </div>
            <div className='CatalogPage__filter-menu'>
                {
                    categories.map(category=>{
                        return(
                            <div className={`col-12 d-flex CatalogPage__category ${searchOptions.category===category.id?'CatalogPage__category--selected':''}`} 
                                            key={category.id} onClick={() => handleCategoryClick(category.id)}>
                                    <div className="d-flex align-items-center ">
                                        <div className={`CatalogPage__icon ${searchOptions.category===category.id?'CatalogPage__icon--selected':''} CatalogPage__icon-cat-${category.icon}`}></div>
                                        <div className={`${searchOptions.category===category.id?'CatalogPage__bold':''}`}>{category.name}</div>
                                    </div>
                            </div>
                        )
                    })
                }
            </div>
            <div className='row mt-4'>
                <div className='col-12'>
                    <span className='CoursePage__categories-title'>Duración</span>
                </div>
            </div>
            <div className='CatalogPage__filter-menu'>
                {
                    duration.map(category=>{
                        return(
                            <div className={`col-12 d-flex CatalogPage__category ${searchOptions.category===category.id?'CatalogPage__category--selected':''}`}
                                 key={category.id}>
                                <div className="d-flex align-items-center ">
                                    <div>
                                        <label className="container" id={`c${category.id}`}><input type="checkbox" onClick={() => handleCategoryClick(category.id)} checked={(searchOptions.category===category.id || searchOptions.category===0) && true}/><span className="checkmark"></span></label>
                                    </div>
                                    <div className={`CatalogPage__search-category ${searchOptions.category===category.id?'CatalogPage__bold':''}`}>{category.name}</div>
                                </div>
                            </div>
                        )
                    })
                }

            </div>
        </>
    );
}


export const CatalogSection = ({filter, user}) => {
    const [showModalFilter, setShowModalFilter] = useState(false);
    const [searchOptions, setSearchOptions] = useState(Object.assign({},filter));
    const handleCloseModal = () =>{
        setShowModalFilter(false);
    }
    useEffect(()=>coursesContainer=document.getElementById(coursesContainerId));

    useEffect(()=>setSearchOptions(filter),[filter]); 
    return (
        <>
            <div className='px-4 px-sm-5'>
                <div className='row'>
                    <FilterMenu 
                        isAModal={false} 
                        searchOptionsSetter = {setSearchOptions} 
                        searchOptions={searchOptions}>
                    </FilterMenu>
                    <CoursesSection 
                        setShowModalFilter={setShowModalFilter} 
                        searchOptions={searchOptions}
                        user={user}
                    >
                    </CoursesSection>
                </div>
                <Modal
                    open={showModalFilter}
                    onClose={handleCloseModal} 
                    aria-labelledby="modal-modal-title"
                    aria-describedby="modal-modal-description"
                >
                    <FilterMenu 
                        handleCloseModal={handleCloseModal} 
                        isAModal={true} 
                        searchOptionsSetter = {setSearchOptions}
                        searchOptions={searchOptions}
                    />
                </Modal>
            </div>
        </>
    )
}
