import React, { useEffect, useState } from 'react'
import { useFetch } from '../../hooks/useFetch';
import { CourseDialogLogin } from './CourseDialogLogin';
import { useAuthToken } from '../../hooks/useAuthToken';
import { AcceptDialog } from '../controls/UIControls';
import { Dialog } from '@mui/material';
import { useUserPlan } from '../../hooks/useUserPlan';
import { useNavigate } from 'react-router-dom';
import { useCourseTaker } from '../../hooks/useCourseTaker';

const landingImages = require.context('../../assets/images', true);
const loadingImage = landingImages('./icons/loading_spin_ean.svg');

export const CourseDetailSection = ({courseID}) => {
  
  return (
    <div>
      <CoursesLoader courseID={courseID} />
    </div>
  )
}
/**
 * Creado por Equilibrio Agency 2022<br>
 * Función de precarga de cursos
 * @param {string} courseID ID del curso a cargar
 * @function CoursesLoader
 */
const CoursesLoader = ({courseID}) => {
  const [data, handleFetch] = useFetch();
  const [course, setCourse] = useState(
    {
      "attr.reviews":"",
      contentData: [{name: '', lessons: [{name: ''}]}],
      description: "",
      duration:"",
      imageURL:"",
      instructorData: {
        avatar_url: "",
        bio: "",
        id: "",
        name: "",
        title: "",
      },
      title: "",
    }
  );

// Función para consulta de curso individual
  const coursesFetcher = () => {
      fetcherFunction(handleFetch, courseID)
      .then( r => {
        setCourse(r);
      },
        error => {
          console.log(error);
        });
                
  }

  useEffect(() => coursesFetcher(), []);
  return (   
    <>     
      <div
          className='justify-content-center justify-content-sm-start position-relative'
      >
          {
              data.loading ?
              <Loading></Loading>
              :
            <CourseDetailed course={course}/>
          }
          
      </div>
    </>
  );
}
/**
 * Creado por Equilibrio Agency 2022<br>
 * Función para consultar curso
 * @param {function} fetcher Función handle para llamado de endpoint
 * @param {string} courseID ID del curso a cargar
 * @function CoursesLoader
 */
const fetcherFunction = (fetcher, courseID) => {
  
  return fetcher({
      url:`catalog/${courseID}`,
      method: 'GET',
  });

}
/**
 * Creado por Equilibrio Agency 2022<br>
 * Función Loading
 * @function Loading
 */
const Loading = (props) => {
  const loaderImage = props.loaderImage || loadingImage;
  return (
      <div style={{minHeight: "300px"}}>
        <div className='row text-center position-absolute CatalogPage--loader'>
            <img alt='Loader' src={loaderImage}/>
        </div>
      </div>
  );
}
// Función detalle de curso
const CourseDetailed = ({course}) =>{

  const [showDescription, setShowDescription] = useState(false);
  const [openDialog, setOpenDialog] = useState(false);
  const [errorDialog, setErrorDialog] = useState(false);
  const [showContent, setShowContent] = useState([false]);
  const {checkUserPlan} = useUserPlan();
  const navigator = useNavigate ();
  // Función mostrar u ocultar contenido
  const handleContentShown = (index) =>{
    if(showContent[index]){

      let auxArr = Array(course.contentData?.length).fill(false);
      setShowContent(auxArr);
    }else{
      let auxArr = Array(course.contentData?.length).fill(false);
      auxArr[index] = true;
      setShowContent(auxArr);

    }
  }

  useEffect(() => {
    setShowContent(Array(course.contentData?.length).fill(false))
  }, [course.contentData])
  
  const show_correct_time = (time_minutes) =>{
    return Math.floor(time_minutes/60)+"h "+time_minutes%60+"m";
  }

  const { isLogged } = useAuthToken ();

  const [data,courseTaker] = useCourseTaker();
  
  
  const onCourseSubscription = () => {
    if(!isLogged()) {
      setOpenDialog(true);
    } else {
      courseTaker(course, () => setErrorDialog(true) );
    } 
  }
  // Función para contar vídeos
  const getVideosCounter = course => {
    let videosCounter = 0;
    if(course.contentData) {
      videosCounter = course.contentData.reduce((counter, unit) => {
        counter+=unit.lessons.reduce((vCounter, lesson) => {
          if(lesson.type && 'ivideo' == lesson.type) vCounter++;
          return vCounter;
        },0);
        return counter;
      },0);
    }
    return videosCounter;
  }

  const nVideos = getVideosCounter(course);
  return(
  <>
    <AcceptDialog 
          open={errorDialog} 
          message="Ocurrió un error en la suscripción al curso, por favor intenta más tarde"
          style='error'
          afterClose={()=>setErrorDialog(false)}></AcceptDialog>
    <CourseDialogLogin openDialog={openDialog} afterDialogClose = {()=>setOpenDialog(false)}></CourseDialogLogin>
    
      <Dialog open={data.loading}  
          disableScrollLock={ true }
          classes={{paper:'CourseDetail__wait-message'}}>
        <div >
            <img alt='Loader' src={loadingImage}/>
            <div>Espera un momento. En breve serás redireccionado a la página del curso... </div>  
        </div>
      </Dialog>
    
    <div className='row justify-content-center'>
      <div className='col-10 col-xl-9 ps-0' style={{marginTop: "30px", marginBottom: "10px"}}>
        <h1 className='CourseDetail__title'>{course.title}</h1>
      </div>
      <div className='col-10 col-xl-9'>
        <div className='row'>
          <div className='col-8 d-none d-lg-flex' style={{alignItems:"flex-start", paddingLeft: "0px"}} >
            <div className='' style={{width: "95%"}}>
              <div className='col-12' style={{padding: "0px"}}>
                <img alt={course.title+" banner"} src={course.imageUrl} style={{maxWidth: "100%", borderRadius: "8px", minWidth: "100%"}}/>
              </div>

            </div>
          </div>
          <div className='col-12 col-lg-4'>
            <div className='row justify-content-center justify-content-lg-start'>
              <div className='col-12 px-0  d-lg-none' style={{marginBottom: "15px"}}>
                <img alt={course.title+" banner"} src={course.imageUrl} style={{maxWidth: "100%", borderRadius: "8px"}}/>
              </div>
              <div className='col-12'>
                <div className='row'>
                  <div className='col-12'>
                    <div className='row justify-content-center'>

                      <div className='col-12 ps-0 d-flex flex-wrap'>
                        <div className='col-12'>
                          <p className='CourseDetail__instructor-name-text'>Por <span className='CourseDetail__instructor-name-course-description'>{course.instructorData.name}</span></p>
                        </div>
                      </div>
                      <div className='col-12 ps-0 d-flex flex-wrap' style={{color: "grey", fontSize: "14px"}}>
                        <div className='row'>
                          <div className='col-5 col-sm-4 col-md-3 col-lg-5 col-xl-4 co-xxl-3 d-flex align-items-baseline justify-content-start'>
                            <i className="fa-solid fa-display"></i><p className='ms-2'>{nVideos} videos</p>
                          </div>
                          <div className='col-6 col-sm-5 col-md-3 col-lg-6 col-xl-6 col-xxl-5 d-flex align-items-baseline justify-content-start'>
                            <i className="fa-solid fa-certificate"></i><p className='ms-2'>Con certificado</p>
                          </div>
                          <div className='col-5 col-sm-4 col-md-3 col-lg-5 col-xl-5 col-xxl-4 d-flex align-items-baseline justify-content-start'>
                            <i className="fa-solid fa-clock"></i><p className='ms-2'>{show_correct_time(course.duration)}</p>
                          </div>
                          <div className='col-4 col-sm-3 col-md-2 col-lg-4 col-xl-3 col-xxl-3 d-flex align-items-baseline justify-content-start'>
                            <i className="fa-solid fa-language"></i><p className='ms-2'>Español</p>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className='col-12 ps-0'>
                    {
                      (!!course.description)?
                        course.description?.length>360
                        ?
                        <>

                        {
                          showDescription
                          ?
                            <>
                              <p className='' style={{fontSize: "16px", fontWeight: 400, margin: "8px 0px"}}>{course.description}</p>
                              <p className='CourseDetail__see-more' onClick={() => {setShowDescription(false)}}>Ver menos</p>
                            </>
                          :
                            <>
                              <p className='' style={{fontSize: "16px", fontWeight: 400, margin: "8px 0px"}}>{course.description.substring(0,360)+"..."}</p>
                              <p className='CourseDetail__see-more' onClick={() => {setShowDescription(true)}}>Ver más</p>
                            </>
                          }
                        </>
                        :
                        <>
                          <p className='' style={{fontSize: "16px", fontWeight: 400, margin: "8px 0px"}}>{course.description}</p>
                        </>
                      :
                      <></>
                    }
                  </div>
                  <div className='col-12 px-0' style={{marginTop: "20px", marginBottom: "20px"}}>
                    <button className='CourseDetail__suscription-button border-0' onClick={onCourseSubscription}>        
                      Iniciar Curso
                    </button>
                  </div>
                </div>
              </div>

            </div>
          </div>
        </div>
      </div>
      <div className='col-10 col-xl-9 ps-0' style={{marginTop: "30px"}}>
        <p style={{fontWeight: "700", fontSize:"28px", letterSpacing: "-0.01em", lineHeight: "140%"}}>Contenido del curso</p>
      </div>
      <hr className='CourseDetail__separation-line col-10 col-xl-9' style={{marginBottom: "20px"}} />
      {
        course.contentData!=null && course.contentData.map((chapter, index) =>{
          return(
            <div  key={`course-lesson-${index}`} className="col-10 col-xl-9 ">
              <div className='ps-0' style={{marginBottom: "20px"}}>
                <div className='d-flex justify-content-between justify-content-lg-start align-items-center' style={{fontSize: "21px", fontWeight: "600"}} onClick={()=>handleContentShown(index)}>
                  <div>

                    {index+1}. {chapter.name} 
                  </div>
                  <div>

                    {
                      showContent[index]
                      ?
                      <i className="fa-solid fa-angle-up" style={{fontSize: "14px", marginLeft: "10px"}}></i>
                      :
                      <i className="fa-solid fa-angle-down" style={{fontSize: "14px", marginLeft: "10px"}}></i>
                    }
                  </div>
                </div>
                {
                  showContent[index]?
                    <ul style={{marginTop: "16px"}}>
                      {chapter?.lessons.map((lesson,index) =>{
                        return(
                          <li key={`lesson-${index}`}>
                            {lesson.name}
                          </li>
                        )
                      })}
                    </ul>
                  :
                  <></>
                }
              </div>
              <hr className='CourseDetail__separation-line' style={{marginBottom: "20px"}} />  
            </div>
          )
        })

      }
    </div>
  </>
  );
}