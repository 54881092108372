import React from 'react'
import { Link } from 'react-router-dom';

const landingImages = require.context('../../assets/images', true);
/**
 * Creado por Equilibrio Agency 2022<br>
 * Función de pie de página
 * @function Footer
 */
export const SimpleFooter = () =>{
    return(
        <div className='container'>
            <div className='row justify-content-center text-start text-md-center mt-2'>
                <div className='col-7 col-md-6 col-lg-6 col-xl-6'>

                    <p>© 2023 Todos los derechos reservados</p>
                </div>
                <div className='col-3 col-md-2 col-lg-1'>
                    <Link to="/" >
                        <img alt='Ean logo' src={landingImages('./ean-logo-sin 1.png')} style={{width: "100%", height: "auto"}} />
                    </Link>
                </div>
            </div>
        </div>
    )
}